<template>
  <div
    id="preview"
    :class="{ grabbable: grabbable }"
    @click="clicked"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import debounce from "lodash-es/debounce";
import { ArcballControls } from "three/examples/jsm/controls/ArcballControls";
import * as TWEEN from "@tweenjs/tween.js";
import {
  AmbientLight,
  Mesh,
  MeshLambertMaterial,
  MeshPhongMaterial,
  OrthographicCamera,
  PlaneGeometry,
  Scene,
  WebGLRenderer,
  DirectionalLight,
} from "three";
import type { PropType } from "vue";
import { Area } from "../src/store";

const CAMERA = { x: 0, y: 0, z: 450 };

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    scene: Scene;
    wireframe: Mesh<PlaneGeometry>;
    camera: OrthographicCamera;
    controls: ArcballControls;
    terrain: Mesh;
    renderer: WebGLRenderer;
    originalScale: number;
  }
}

export default defineComponent({
  name: "Preview",
  props: {
    rect: { type: Object as PropType<Area>, required: false },
  },
  data() {
    return {
      shouldAnimate: true,
      autorotate: false,
    };
  },
  computed: {
    heightmap() {
      return this.$store.state.heightmap;
    },
    grabbable() {
      return this.$store.state.mode === "preview";
    },
    amplification() {
      return this.$store.state.amplification;
    },
    planet() {
      return this.$store.state.planet;
    },
  },
  methods: {
    addRect(parentWidth: number, parentHeight: number) {
      let swpx;
      let nepx;

      if (this.rect) {
        swpx = this.rect.swpx;
        nepx = this.rect.nepx;
      }

      const width = swpx && nepx ? Math.abs(nepx.x - swpx.x) : 500;
      const height = swpx && nepx ? Math.abs(nepx.y - swpx.y) : 500;

      const wireframeGeometry = new PlaneGeometry(width, height, 10, 10);
      const material = new MeshPhongMaterial({ color: 0x25da4e });
      material.wireframe = true;

      const wireframe = new Mesh(wireframeGeometry, material);
      if (swpx) {
        wireframe.position.x = swpx.x - parentWidth / 2 + width / 2;
        wireframe.position.y = -1 * swpx.y + parentHeight / 2 + height / 2;
      } else {
        wireframe.position.x = 0;
        wireframe.position.y = 0;
        wireframe.visible = false;
      }

      this.wireframe = wireframe;
      this.scene.add(wireframe);
    },
    async addTerrain() {
      let src;
      if (this.heightmap) {
        src = this.heightmap;
      } else {
        const { sw, ne } = this.rect as Area;
        src = `/data/${
          this.planet
        }/${sw.lat()},${sw.lng()};${ne.lat()},${ne.lng()}/`;
      }
      const resp = await fetch(src);
      const terrainHeightData = new Float32Array(await resp.arrayBuffer());

      const heightMapWidth = Number(resp.headers.get("x-heightmap-width"));
      const heightMapDepth = Number(resp.headers.get("x-heightmap-depth"));
      const heightMapScale = Number(resp.headers.get("x-heightmap-scale"));

      const material = new MeshLambertMaterial({ color: 0xffffff });
      const geometryTerrain = new PlaneGeometry(
        heightMapWidth,
        heightMapDepth,
        heightMapWidth - 1,
        heightMapDepth - 1,
      );
      const terrain = new Mesh(geometryTerrain, material);

      const { position } = terrain.geometry.attributes;
      for (let i = 0, l = position.count; i < l; i += 1) {
        position.setZ(i, terrainHeightData[i] * heightMapScale + 1);
      }

      geometryTerrain.computeVertexNormals();

      const scale = this.wireframe.geometry.parameters.width / heightMapWidth;
      terrain.scale.x = scale;
      terrain.scale.y = scale;
      terrain.scale.z = scale * this.amplification;
      this.originalScale = scale;

      if (this.wireframe.visible === false) {
        this.wireframe.scale.x = (scale * heightMapWidth) / 500;
        this.wireframe.scale.y = (scale * heightMapDepth) / 500;
        this.wireframe.visible = true;
      }

      terrain.position.copy(this.wireframe.position);

      this.scene.add(terrain);
      this.terrain = terrain;

      this.$store.commit("setPreview");
      this.animate(undefined);
    },
    animate(time: number | undefined) {
      if (!this.shouldAnimate) return;
      if (this.terrain && this.autorotate) {
        this.terrain.rotation.z += 0.01;
        this.wireframe.rotation.z += 0.01;
      }
      requestAnimationFrame(this.animate);
      TWEEN.update(time);

      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
    animateCamera() {
      const cam = { ...CAMERA };
      const cameraTween = new TWEEN.Tween(cam)
        .to({ x: 0, y: -300, z: 200 }, 4000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate((pos) => {
          this.camera.position.x = pos.x;
          this.camera.position.y = pos.y;
          this.camera.position.z = pos.z;
        })
        .onComplete(() => {
          this.autorotate = true;
        });

      new TWEEN.Tween(this.terrain.position)
        .to({ x: 0, y: 0 }, 500)
        .delay(1000)
        .onUpdate((pos) => {
          this.wireframe.position.x = pos.x;
          this.wireframe.position.y = pos.y;
        })
        .chain(cameraTween)
        .start();
    },
    clicked() {
      if (this.autorotate) {
        this.autorotate = false;
      }
    },
    onResize() {
      const width = this.$el.parentNode.clientWidth;
      const height = this.$el.parentNode.clientHeight;
      this.camera.left = width / -2;
      this.camera.right = width / 2;
      this.camera.bottom = height / -2;
      this.camera.top = height / 2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    },
  },
  mounted() {
    // Add renderer
    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1,
    );
    const parentWidth = this.$el.parentNode.clientWidth;
    const parentHeight = this.$el.parentNode.clientHeight;
    this.renderer.setSize(parentWidth, parentHeight);
    this.$el.appendChild(this.renderer.domElement);

    this.scene = new Scene();

    // Add camera
    this.camera = new OrthographicCamera(
      parentWidth / -2,
      parentWidth / 2,
      parentHeight / 2,
      parentHeight / -2,
      1,
      1000,
    );
    this.scene.add(this.camera);
    this.camera.position.z = CAMERA.z;
    this.camera.position.x = CAMERA.x;
    this.camera.position.y = CAMERA.y;
    this.camera.lookAt(this.scene.position);

    // Add lights
    this.scene.add(new AmbientLight(0x444444));

    const pointLight = new DirectionalLight(0xffffff);
    pointLight.position.x = 500;
    pointLight.position.y = 0;
    pointLight.position.z = 500;
    pointLight.intensity = 2.6;
    this.scene.add(pointLight);

    const controls = new ArcballControls(this.camera, this.renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.rotateSpeed = 1.0;
    this.controls = controls;

    this.addRect(parentWidth, parentHeight);

    if (this.rect) {
      this.addTerrain()
        .then(this.animateCamera)
        .catch(() => {
          // TODO Handle error
        });
    }

    this.renderer.render(this.scene, this.camera);

    window.addEventListener("resize", debounce(this.onResize, 300));
  },
  beforeUnmount() {
    this.shouldAnimate = false;
    this.renderer.dispose();
  },
  watch: {
    amplification(factor) {
      this.terrain.scale.z = this.originalScale * factor;
    },
    heightmap() {
      this.addTerrain()
        .then(this.animateCamera)
        .catch(() => {
          // TODO Handle error
        });
    },
  },
});
</script>

<style>
#preview {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 4px;
}

.grabbable canvas {
  cursor: grab;
}
</style>
