type Point = { x: number; y: number };

type UrlFunction = (p: Point, z: number) => string;

function getNormalizedCoord(coord: Point, zoom: number): Point | null {
  const { y } = coord;
  let { x } = coord;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return {
    x,
    y,
  };
}

const moonMapType = (gmaps: typeof google.maps) => {
  const moonTypeOptions: google.maps.ImageMapTypeOptions = {
    getTileUrl(coord, zoom) {
      const normalizedCoord = getNormalizedCoord(coord, zoom);
      if (!normalizedCoord) {
        return null;
      }
      const bound = 2 ** zoom;
      return `https://mw1.google.com/mw-planetary/lunar/lunarmaps_v1/clem_bw/${zoom}/${
        normalizedCoord.x
      }/${bound - normalizedCoord.y - 1}.jpg`;
    },
    tileSize: new gmaps.Size(256, 256),
    maxZoom: 7,
    minZoom: 0,
    // @ts-expect-error TODO 'radius' does not exist in type 'ImageMapTypeOptions'
    radius: 1738000,
    name: "Moon",
  };

  return new gmaps.ImageMapType(moonTypeOptions);
};

// MARS

function getHorizontallyRepeatingTileUrl(
  coord: Point,
  zoom: number,
  urlfunc: UrlFunction,
) {
  const { y } = coord;
  let { x } = coord;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return urlfunc({ x, y }, zoom);
}

const marsMapType = (gmaps: typeof google.maps) => {
  function getMarsTileUrl(baseUrl: string, coord: Point, zoom: number): string {
    let bound = 2 ** zoom;
    let { x, y } = coord;
    const quads = ["t"];

    for (let z = 0; z < zoom; z += 1) {
      bound /= 2;
      if (y < bound) {
        if (x < bound) {
          quads.push("q");
        } else {
          quads.push("r");
          x -= bound;
        }
      } else if (x < bound) {
        quads.push("t");
        y -= bound;
      } else {
        quads.push("s");
        x -= bound;
        y -= bound;
      }
    }

    return `${baseUrl + quads.join("")}.jpg`;
  }

  const marsTypeOptions: google.maps.ImageMapTypeOptions = {
    getTileUrl(coord, zoom) {
      return getHorizontallyRepeatingTileUrl(
        coord,
        zoom,
        (c: Point, z: number) =>
          getMarsTileUrl(
            "https://mw1.google.com/mw-planetary/mars/elevation/",
            c,
            z,
          ),
      );
    },
    tileSize: new gmaps.Size(256, 256),
    // @ts-expect-error TODO 'isPng' does not exist in type 'ImageMapTypeOptions'
    isPng: false,
    maxZoom: 7,
    minZoom: 0,
    radius: 3396200,
    name: "Mars",
  };
  return new gmaps.ImageMapType(marsTypeOptions);
};

export { moonMapType, marsMapType };
