import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import store from "./store";
import TerrainSelector from "../components/TerrainSelector.vue";

const app = createApp(TerrainSelector);
app.use(store);

Sentry.init({
  app,
  dsn: process.env.RAVEN_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.2,
});

app.mount("#main");
