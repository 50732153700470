<template>
  <div id="map-container" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import invariant from "tiny-invariant";
import type { PropType } from "vue";
import { moonMapType, marsMapType } from "../src/map-types";
import { type Planet } from "../src/store";

type RectangleCompleteEvent = {
  bounds: google.maps.LatLngBounds;

  setMap: (map: google.maps.Map | null) => void;
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    map: google.maps.Map;
  }
}

export default defineComponent({
  name: "Map",
  props: {
    bounds: {
      type: Object as PropType<google.maps.LatLngBounds>,
      required: false,
    },
    sessionToken: Object,
    planet: { type: String as PropType<Planet>, required: true },
    google: { type: Object as PropType<typeof google>, required: true },
  },
  data() {
    return {};
  },
  async mounted() {
    const {
      google: { maps: gmaps },
    } = this;

    const mapOptions = {
      center: new gmaps.LatLng(20, 0),
      zoom: 2,
      mapTypeId: gmaps.MapTypeId.TERRAIN,
      panControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      minZoom: 2,
      maxZoom: 13,
    };

    this.map = new gmaps.Map(this.$el, mapOptions);
    if (this.planet === "moon") {
      this.map.mapTypes.set("moon", moonMapType(gmaps));
      this.map.setMapTypeId("moon");
    } else if (this.planet === "mars") {
      this.map.mapTypes.set("mars", marsMapType(gmaps));
      this.map.setMapTypeId("mars");
    }

    // Add helper. This doesn't do anything other than provide us with a MapCanvasProjection
    const helper = new gmaps.OverlayView();
    helper.draw = () => {};
    helper.setMap(this.map);

    const drawingManager = new gmaps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [gmaps.drawing.OverlayType.RECTANGLE],
      },
      rectangleOptions: {
        strokeColor: "#25DA4E",
        strokeWeight: 1,
      },
    });
    drawingManager.setMap(this.map);

    gmaps.event.addListener(this.map, "zoom_changed", () => {
      const zoom = this.map.getZoom();
      invariant(zoom);
      drawingManager.setOptions({ drawingControl: zoom >= 6 });
      if (zoom >= 6) {
        this.$store.commit("setZoomed");
      }
    });

    gmaps.event.addListener(drawingManager, "drawingmode_changed", () => {
      if (drawingManager.getDrawingMode() === "rectangle") {
        this.$store.commit("setToolSelected");
      } else {
        this.$store.commit("setZoomed");
      }
    });

    gmaps.event.addListener(
      drawingManager,
      "rectanglecomplete",
      (rect: RectangleCompleteEvent) => {
        const zoom = this.map.getZoom();
        const projection = helper.getProjection();
        const ne = rect.bounds.getNorthEast();
        const sw = rect.bounds.getSouthWest();
        const nepx = projection.fromLatLngToContainerPixel(ne);
        const swpx = projection.fromLatLngToContainerPixel(sw);
        const { planet } = this;

        this.$store.commit("setSelectedArea", {
          planet,
          swpx,
          nepx,
          sw,
          ne,
          zoom,
        });

        this.$store.watch(
          (state) => state.selectedArea === null,
          () => {
            if (this.$store.state.selectedArea === null) {
              rect.setMap(null);
              drawingManager.setDrawingMode(null);
            }
          },
        );
      },
    );
  },
  watch: {
    bounds() {
      if (this.bounds) this.map.fitBounds(this.bounds);
    },
  },
});
</script>

<style>
#map-container {
  background-color: #88b3f5;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.domVisible #map-container {
  opacity: 0;
}
</style>
