import { getCookie } from "typescript-cookie";
import invariant from "tiny-invariant";
import { createStore, Store } from "vuex";

let iteration = 1;

export type Planet = "earth" | "moon" | "mars";

export type Area = {
  planet: Planet;
  sw: google.maps.LatLng;
  ne: google.maps.LatLng;
  swpx?: google.maps.Point;
  nepx?: google.maps.Point;
  zoom: number;
};

type State = {
  planet: Planet;
  amplification: number;
  scaleFactor: number;
  mode: "zoom" | "select" | "load" | "tool_selected" | "preview";
  selectedArea: Area | null;
  width: number | null;
  height: number | null;
  depth: number | null;
  volume: number | null;
  scale: number | null;
  download: string | null;
  heightmap: string | null;
  bounds: google.maps.LatLngBounds | null;
};

const store = createStore<State>({
  state: {
    planet: "earth",
    amplification: 1.0,
    scaleFactor: 1.0,
    mode: "zoom",
    selectedArea: null,
    width: null,
    height: null,
    depth: null,
    volume: null,
    scale: null,
    download: null,
    heightmap: null,
    bounds: null,
  },
  getters: {
    scaledHeight(state) {
      invariant(state.height);
      return state.height * state.amplification * state.scaleFactor;
    },
    scaledWidth(state) {
      invariant(state.width);
      return state.width * state.scaleFactor;
    },
    scaledDepth(state) {
      invariant(state.depth);
      return state.depth * state.scaleFactor;
    },
    scaledScale(state) {
      invariant(state.scale);
      return state.scale / state.scaleFactor;
    },
  },
  mutations: {
    reset(state) {
      state.mode = "select";
      state.selectedArea = null;
    },
    setSelectedArea(state, rect) {
      state.mode = "load";
      state.selectedArea = rect;
      gtag("event", "selected", {
        event_category: "preview",
      });
    },
    setPlanet(state, planet: Planet) {
      state.planet = planet;
    },
    setPreview(state) {
      state.mode = "preview";
    },
    setZoomed(state) {
      state.mode = "select";
    },
    setToolSelected(state) {
      state.mode = "tool_selected";
    },
    changeAmplification(state, val) {
      state.amplification = val;
    },
    changeScaleFactor(state, val) {
      state.scaleFactor = val;
    },
    gotData(state, data) {
      state.planet = data.planet;
      state.height = data.height;
      state.width = data.width;
      state.depth = data.depth;
      state.volume = data.volume;
      state.scale = data.scale;
      state.download = data.download;
      state.heightmap = data.heightmap;
      state.bounds = data.bounds;
    },
  },
  actions: {
    async createTerrain(context) {
      if (!context.state.selectedArea) return;
      const { planet, sw, ne } = context.state.selectedArea;

      const data = {
        planet,
        latitude_min: sw.lat(),
        latitude_max: ne.lat(),
        longitude_min: sw.lng(),
        longitude_max: ne.lng(),
      };
      const csrfToken = getCookie("csrftoken") as string;
      const response = await fetch("/api/terrains/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(data),
      });

      window.location = (await response.json()).page;
    },
    async fetchModel({ commit, dispatch }, url) {
      const response = await fetch(url);
      const data = await response.json();
      commit("gotData", data);
      if (!(data.volume && data.heightmap)) {
        setTimeout(
          () => {
            iteration += 1;
            dispatch("fetchModel", url);
          },
          iteration ** 2 * 1000,
        );
      }
    },
  },
});

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

export default store;
