<template>
  <div
    id="searchable-map"
    :class="{ faded: hidden }"
  >
    <div
      id="progress"
      :class="{ invisible: !loading }"
    />
    <SearchBox
      v-if="google && planet === 'earth'"
      :session-token="sessionToken"
      :google="google"
      @select="select"
    />
    <StaticSearchBox
      v-if="planet !== 'earth'"
      :planet="planet"
      @select="select"
    />
    <Map
      v-if="google"
      :bounds="bounds"
      :session-token="sessionToken"
      :google="google"
      :planet="planet"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import Map from "./Map.vue";
import SearchBox from "./SearchBox.vue";
import StaticSearchBox from "./StaticSearchBox.vue";

export default defineComponent({
  name: "SearchableMap",
  components: { Map, SearchBox, StaticSearchBox },
  props: { planet: { type: String, required: true } },
  data() {
    return {
      bounds: null as google.maps.LatLngBounds | null,
      google: null as typeof google | null,
      sessionToken: null as google.maps.places.AutocompleteSessionToken | null,
    };
  },
  async created() {
    const loader = new Loader({
      apiKey: "AIzaSyAQOk6QbTDuD1D5T0Z27nSoIhq4VFOes8s",
      version: "quarterly",
      libraries: ["maps", "places", "drawing"],
    });

    const google = await loader.load();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.google = google;
  },
  computed: {
    hidden() {
      return this.$store.state.mode === "preview";
    },
    loading() {
      return this.$store.state.mode === "load";
    },
  },
  methods: {
    select(bounds: google.maps.LatLngBounds) {
      this.bounds = bounds;
    },
  },
});
</script>

<style>
@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

#progress {
  height: 4px;
  background-color: var(--termgreen);
  position: relative;
}

#progress::after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    -45deg,
    rgb(0 0 0 / 20%) 25%,
    transparent 25%,
    transparent 50%,
    rgb(0 0 0 / 20%) 50%,
    rgb(0 0 0 / 20%) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
}

#searchable-map {
  height: 100%;
  transition: opacity 2s linear;
}

.faded {
  opacity: 0;
}
</style>
