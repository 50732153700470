<template>
  <div
    id="tour"
    :class="{ flipHide: hidden }"
  >
    <p>{{ msg }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const strings: { [key: string]: string } = {
  select: "When you're ready, use the selection tool above to choose an area",
  zoom: "Start by zooming to, or searching for, an area of interest",
};

export default defineComponent({
  name: "Tour",
  data() {
    return {};
  },
  computed: {
    msg(): string {
      return strings[this.$store.state.mode];
    },
    hidden() {
      return ["load", "preview", "tool_selected"].includes(
        this.$store.state.mode,
      );
    },
  },
  methods: {},
});
</script>

<style>
#tour {
  z-index: 80;
  position: absolute;
  inset: calc(50% - 24px) 0 0;
  margin: 0 auto;
  height: 48px;
  width: 80%;
  max-width: 400px;
  pointer-events: none;
}

#tour p {
  font-size: 12px;
  color: var(--termgreen);
  text-align: center;
  background: rgb(40 40 40 / 90%);
  padding: 8px;
  border-radius: 5px;
  border: 2px solid var(--termgreen);
}

#tour.flipHide {
  display: none;
}
</style>
