<template>
  <div id="autosuggest">
    <Combobox v-model="selected">
      <ComboboxInput
        autocomplete="off"
        spellcheck="false"
        placeholder="Search for a place"
        :displayValue="(suggestion) => suggestion.name"
        @change="onInputChange($event.target.value)"
      />
      <ComboboxOptions>
        <!-- eslint-disable vue/valid-v-for -->[]
        <ComboboxOption
          v-for="suggestion in suggestions"
          v-slot="{ active }"
          :key="suggestion.name"
          :value="suggestion"
          as="template"
        >
          <!-- eslint-enable -->
          <li
            :class="{
              active: active,
            }"
          >
            {{ suggestion.name }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";

export default defineComponent({
  name: "SearchBox",
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  },
  props: { planet: { type: String, required: true } },
  emits: ["select"],
  data() {
    return {
      suggestions: [],
    };
  },
  computed: {
    selected: {
      get() {
        return undefined;
      },
      set(value) {
        this.onSelected(value);
      },
    },
  },
  created() {
    this.getFeatures();
  },
  methods: {
    async getFeatures() {
      try {
        const resp = await fetch(`/api/planets/${this.planet}/features.json`);
        if (resp.ok) {
          const x = await resp.json();
          this.features = x;
        } else {
          throw new Error("Couldn't fetch features");
        }
      } catch (e) {
        console.error(e);
      }
    },
    onInputChange(text) {
      if (text.length < 2) return;
      const data = this.features.filter(
        (option) => option.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
      );
      this.suggestions = data;
    },
    onSelected(item) {
      this.$emit("select", item.bbox);
    },
  },
});
</script>
