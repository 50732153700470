<template>
  <div id="selector">
    <Tour />
    <SearchableMap :planet="planet" />
    <Preview
      v-if="rect"
      :rect="rect"
      :planet="planet"
    />
    <button
      v-if="preview"
      class="back btn"
      @click="back"
    >
      Back
    </button>
    <button
      v-if="preview"
      class="save btn"
      @click="submit"
    >
      Save
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Preview from "./Preview.vue";
import SearchableMap from "./SearchableMap.vue";
import Tour from "./Tour.vue";

export default defineComponent({
  name: "TerrainSelector",
  components: { Preview, SearchableMap, Tour },
  data() {
    return {};
  },
  computed: {
    rect() {
      return this.$store.state.selectedArea;
    },
    preview() {
      return this.$store.state.mode === "preview";
    },
    loggedIn() {
      // This is the old logic... Could do better.
      return document.getElementsByClassName("login").length === 0;
    },
    planet() {
      return this.$store.state.planet;
    },
  },
  methods: {
    back() {
      this.$store.commit("reset");
    },
    submit() {
      if (this.loggedIn) {
        this.$store.dispatch("createTerrain");
      } else if (confirm("You aren't signed in. Do you want to do that now?")) {
        // TODO Stash the model and reinstate properly
        window.location.href = document
          .getElementsByClassName("login")[0]
          .getAttribute("href") as string;
      }
    },
  },
});
</script>

<style>
#selector {
  position: relative;
  height: 550px;
}

button.save {
  position: absolute;
  right: 0;
  bottom: 20px;
}

@media (width <= 760px) {
  #selector {
    height: 350px;
  }
}
</style>
