<template>
  <div id="autosuggest">
    <Combobox v-model="selected">
      <ComboboxInput
        autocomplete="off"
        spellcheck="false"
        placeholder="Search for a place"
        :displayValue="
          (suggestion: google.maps.places.AutocompletePrediction) =>
            suggestion.description
        "
        @change="onInputChange($event.target.value)"
      />
      <ComboboxOptions>
        <!-- eslint-disable vue/valid-v-for -->
        <ComboboxOption
          v-for="suggestion in suggestions"
          v-slot="{ active }"
          :key="suggestion.place_id"
          :value="suggestion"
          as="template"
        >
          <!-- eslint-enable -->
          <li
            :class="{
              active: active,
            }"
          >
            {{ suggestion.description }}
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
    <div id="attribution" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";

export default defineComponent({
  name: "SearchBox",
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  },
  props: {
    sessionToken: { type: Object, required: true },
    google: { type: Object as PropType<typeof google>, required: true },
  },
  emits: ["select"],
  data() {
    return {
      suggestions: [] as google.maps.places.AutocompletePrediction[],
    };
  },
  computed: {
    selected: {
      get() {
        return undefined;
      },
      set(value: google.maps.places.AutocompletePrediction) {
        this.onSelected(value);
      },
    },
  },
  methods: {
    async onInputChange(text: string) {
      const gmaps = this.google.maps;
      if (text.length < 2) return;
      const options: google.maps.places.AutocompletionRequest = {
        input: text,
        types: ["geocode"],
        sessionToken: this.sessionToken,
      };
      const service = new gmaps.places.AutocompleteService();
      const response = await service.getPlacePredictions(options);
      this.suggestions = response.predictions;
    },
    onSelected(item: google.maps.places.AutocompletePrediction) {
      const gmaps = this.google.maps;
      if (item === null) return;
      const service = new gmaps.places.PlacesService(
        document.getElementById("attribution") as HTMLDivElement,
      );
      service.getDetails(
        {
          placeId: item.place_id,
          sessionToken: this.sessionToken,
        },
        (place, status) => {
          if (
            status === gmaps.places.PlacesServiceStatus.OK &&
            place &&
            place.geometry
          ) {
            this.$emit("select", place.geometry.viewport);
          }
        },
      );
    },
  },
});
</script>

<style>
#autosuggest {
  padding: 6px;
  width: 230px;
  position: absolute;
  z-index: 100;
  right: 0;
  top: 4px;
}

#autosuggest input {
  position: relative;
  display: block;
  font-size: 12px;
  border: none;
  box-shadow: rgb(0 0 0 / 29.8%) 0 1px 4px -1px;
  border-radius: 2px;
  padding: 8px 10px;
  width: 100%;
  color: black;
}

#autosuggest ul {
  background: #282828e6;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 6px 0;
  z-index: 100001;
  max-height: 400px;
  overflow-y: auto;

  &:empty {
    visibility: hidden;
  }
}

#autosuggest ul li {
  padding: 5px 10px;
  cursor: pointer;

  &.active {
    background: black;
    color: white;
  }
}
</style>
